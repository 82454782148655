import 'form-association-polyfill'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'


(function() {

    // Promise all settled
    if (!Promise.allSettled) {
        const allSettled = (promises: Promise<any>[]) => {
            const wrappedPromises = promises.map(p => Promise.resolve(p).then(val => ({
                status: 'fulfilled',
                value: val,
            }), err => ({ status: 'rejected', reason: err })))
            return Promise.all(wrappedPromises)
        }
        Promise.allSettled = allSettled as any
    }

    // Custom Event
    if (!window.CustomEvent) {
        const CustomEvent = (event: any, params: any) => {
            params = params || { bubbles: false, cancelable: false, detail: undefined }
            const evt = document.createEvent('CustomEvent')
            evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
            return evt
        }
        CustomEvent.prototype = window.Event.prototype
        window.CustomEvent = CustomEvent as any
    }
})()
