
const isCaptchaActive = Boolean(process.env.REACT_APP_PARAMETER_RECAPTCHA_ENABLE)
const parameterCaptcha = process.env.REACT_APP_PARAMETER_RECAPTCHA

const captchaSubmitPromise = () =>
    new Promise<string>((resolve, reject) => {
        const windowObject = window as any
        if (isCaptchaActive && windowObject.grecaptcha && Boolean(process.env.REACT_APP_PARAMETER_RECAPTCHA!)) {
            windowObject.grecaptcha.ready(async () => {
                try {
                    const token = await windowObject.grecaptcha.execute(
                        process.env.REACT_APP_PARAMETER_RECAPTCHA,
                        {
                            action: 'submit',
                        }
                    )
                    resolve(token)
                } catch (e) {
                    reject(e)
                }
            })
        }
    })

const parameterCaptchaMiddleware = (next: any) => async (req: any) => {
    let captchaSubmitInProgress: Promise<string> | null = null
        const isRecaptchaTrue = req.variables['enableCaptcha']
        if (isRecaptchaTrue && isCaptchaActive && Boolean(parameterCaptcha)) {
                if (!captchaSubmitInProgress) {
                    captchaSubmitInProgress = Promise.resolve(captchaSubmitPromise())
                        .then((token: string) => {
                            captchaSubmitInProgress = null
                            return token
                        })
                        .catch((err) => {
                            captchaSubmitInProgress = null
                            throw err
                        })
                }
                return captchaSubmitInProgress.then((token) => {
                    const newReq = req.clone()
                    newReq.fetchOpts.headers['g-recaptcha-response'] = token
                    return next(newReq)
                })
        }
        return await next(req)

    }
export default parameterCaptchaMiddleware
