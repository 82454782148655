import { Parents } from '@saastack/core'
import { PubSub } from '@saastack/pubsub'
import { loginUrl, parseId, store } from '@saastack/utils'
import urlJoin from 'proper-url-join'
import { Middleware } from 'react-relay-network-modern'

const queryParamsMiddleware: Middleware = (next) => async (req) => {
    PubSub.publish('app/loading', true)
    const parents: Parents = store.get('parents', {
        groupId: '',
        companyId: '',
        locationId: '',
        userId: '',
    })
    const code = new URLSearchParams(window.location.search).get('code') ?? ''
    const queryId = (() => {
        try {
            const body = JSON.parse(req.fetchOpts.body as string)
            return body.id
        } catch (e) {}
        return ''
    })()
    const groupId =
        parents.groupId ||
        parseId(parents.companyId).parent ||
        parseId(parseId(parents.locationId).parent).parent
    const companyId = parseId(parents.companyId).entityId || parseId(parents.locationId).parent
    const locationId = parseId(parents.locationId).entityId
    const userId = parents.userId ?? ''
    req.fetchOpts.url = urlJoin(req.fetchOpts.url, {
        query: {
            groupId,
            companyId,
            locationId,
            userId,
            queryId,
            code
        },
    })
    const res = await next(req)
    const error = res.errors?.[0] as any
            if (error?.extensions?.code === 'Unauthenticated') {
                PubSub.publish('reload', userId)
            }
    PubSub.publish('app/loading', false)
    return res
}

export default queryParamsMiddleware
