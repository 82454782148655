import React from 'react'
import appointyPreloader from '../assets/appointyPreloader.gif'
import preloader from '../assets/preloader.gif'
import { SlugInfo } from '@appointy/client-core/hooks/useSlugInfo'

interface Props {
    slugInfo: SlugInfo['slugInfo']
}

const Preloader: React.FC<Props> = (props) => {
    const hideAppointyPreloader =
        Boolean(process.env.REACT_APP_HIDE_APPOINTY_PRELOADER) ||
        !props.slugInfo ||
        Boolean(props.slugInfo?.hideLoader)

    return (
        <div className="main-loader">
            <img alt="" src={hideAppointyPreloader ? preloader : appointyPreloader} />
        </div>
    )
}

export default Preloader
