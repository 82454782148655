import { FC, useEffect, useMemo } from 'react'
import { NetworkPolicyProvider, withEnvironment } from '@saastack/relay'
import { Script, useDeepCompare } from '@saastack/utils'
import urlJoin from 'proper-url-join'
import { FetchPolicy } from 'react-relay'
import Preloader from './components/Preloader'
import useBootstrapApp from '@appointy/client-core/hooks/useBootstrapApp'
import useUrlParser from '@appointy/client-core/hooks/useUrlParser'
import middlewares from './middlewares'
import AppDataProvider from '@appointy/client-core/providers/AppDataProvider'
import templates from './templates'
import { use100vh } from '@appointy/client-core/utils/use100vh'
import useAnalytics from '@appointy/client-core/hooks/useAnalytics'
import { ParentInfo } from '@appointy/client-core/hooks/useParentQuery'
import getLocale from './utils/getLocale'

const fetchPolicy = (process.env.REACT_APP_FETCH_POLICY as FetchPolicy) || 'network-only'
const refetchPolicy = process.env.REACT_APP_REFETCH_POLICY as FetchPolicy

const getTemplate = (parentInfo: ParentInfo['parentInfo']) => {
    if (parentInfo?.appsDetail.app.find((a) => a.name === 'Meetings')) {
        return 'meeting'
    }

    return process.env.REACT_APP_TEMPLATE_NAME || 'minimal'
}

const App: FC = () => {
    const { loaded, loading, hasError, ...appData } = useBootstrapApp(getLocale)

    useEffect(() => {
        if (hasError && !appData?.parsedUrl?.isGadget) {
            window.location.href = urlJoin(location.origin, '404.html')
        }
    }, [hasError])

    if (loading) {
        return <Preloader {...appData} />
    }

    const activeTemplate = getTemplate(appData.parentInfo)
    if (loaded && !hasError) {
        const Template = templates[activeTemplate]
        if (Template) {
            return (
                <NetworkPolicyProvider
                    fetchPolicy={fetchPolicy}
                    refetchPolicy={refetchPolicy}
                    retain
                >
                    <AppDataProvider {...appData} Template={Template} />
                </NetworkPolicyProvider>
            )
        }
    }

    return null
}

const Wrapper: FC = () => {
    useAnalytics()
    use100vh()
    const parsedUrl = useUrlParser()
    const parts = parsedUrl?.slug?.split('~') ?? []
    const slug = parts[0]

    const headers: Record<string, string> = !!parsedUrl?.queryParams?.auth_token
        ? {
              Authorization: `Bearer ${parsedUrl.queryParams.auth_token as string}`,
              'x-slug': slug,
          }
        : {}
    const memoizedHeader = useDeepCompare(headers)
    const WrappedComponent = useMemo(
        () =>
            withEnvironment({
                graphqlUrl: process.env.REACT_APP_GRAPHQL_URL,
                headers,
                middlewares,
            })(App),
        [memoizedHeader]
    )

    return <CaptchaWrapper><WrappedComponent /></CaptchaWrapper>
}

const CaptchaWrapper: FC = ({ children }) => {
    if (Boolean(process.env.REACT_APP_PARAMETER_RECAPTCHA_ENABLE)) {
        return (
            <Script
            name="recaptcha"
            src={`https://www.recaptcha.net/recaptcha/api.js?render=${process.env.REACT_APP_PARAMETER_RECAPTCHA}`}
        >
            {children}
        </Script>
        )
    }
    return <>{children}</>
}


export default Wrapper
