import loadable from '@loadable/component'
import { TemplateLoaderProps } from '@saastack/core/types'

const MeetingTemplate = loadable(() => import('@appointy/client-meetings-template'))
const MinimalTemplate = loadable(() => import('@appointy/client-minimal-template'))
const MathnasiumTemplate = loadable(() => import('@appointy/client-mathnasium-template'))

const templates: Record<string, TemplateLoaderProps['template']> = {
    meeting: MeetingTemplate,
    minimal: MinimalTemplate,
    radius: MathnasiumTemplate,
}

export default templates
